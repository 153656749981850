import React from 'react';
import {privacy} from './privacy.js';

const PrivacyPolicy = () => {
    return (
        <div dangerouslySetInnerHTML={{__html:privacy}}>

        </div>
    );
};

export default PrivacyPolicy;
