import React from "react";
import {Avatar, Box, Divider, IconButton, Menu, MenuItem, Toolbar, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import AppBar from "@mui/material/AppBar";
import {useAppContext} from "../config/AppContext";
import {auth} from "../config/firebaseApp";
import {signOut} from "firebase/auth";
import {useNavigate} from "react-router-dom";


export const Header1 = ({open, onToggle, isAuth, eventName}) => {
    return (
        <AppBar position={'sticky'} sx={{backgroundColor: 'white', color: 'black'}}>
            <Toolbar>
                <Typography
                    variant={'h3'}><strong>{"911Lawyer"}</strong></Typography>
                {/*<MenuButton onToggle={onToggle} variant={"dark"}/>*/}
                {/*<Input*/}
                {/*  startAdornment={<InputAdornment position={"start"}><IconSearch size={18}/></InputAdornment>}*/}
                {/*  placeholder={"Search"}*/}
                {/*  sx={{border: "0 !important", "&::before": {border: 0}}}*/}
                {/*  InputProps={{sx: {border: '0 !important'}}}*/}
                {/*/>*/}
                <div style={{marginLeft: "auto"}}/>
                {/*<IconButton><IconBell/></IconButton>*/}
                {isAuth ? <UserButton/> : null}
            </Toolbar>
        </AppBar>
    )
}

const UserButton = () => {
    const {state: {currentUser}, dispatch} = useAppContext()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    async function handleClose  () {
        await signOut(auth);
        navigate('/login')
        setAnchorEl(null);
    };

    function stringAvatar(name) {
        if (!currentUser) return "L"
        return {
            children: name[0].toUpperCase(),
        };
    }


    return (
        <div>
            <IconButton onClick={handleClick} size={"small"}>
                <Avatar src={currentUser?.photoURL} {...stringAvatar(currentUser?.email)}/>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    sx: {minWidth: "200px", borderRadius: 3}
                }}
                slotProps={{
                    sx: {borderRadius: 3}
                }}
            >
                <Box sx={{px: 2, pt: 1}}>
                    {/*<Typography fontWeight={700} fontSize={15}>Account</Typography>*/}
                    <Typography fontWeight={400} fontSize={13}>{currentUser?.email}</Typography>
                </Box>
                <Divider color={grey[200]} sx={{my: 1}}/>
                <MenuItem onClick={() => {
                    handleClose();
                }}>Logout</MenuItem>
            </Menu>
        </div>
    );
}
