import React, {createContext, useContext, useReducer} from 'react'

export const AppContext = createContext();

export const useAppContext = () => {
    return useContext(AppContext);
}


const initialState = {
    currentUser: null,
}

const reducer = (state, action) => {
    return {...state, ...action}
};

export const AppContextProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AppContext.Provider value={{state, dispatch}}>
            {props.children}
        </AppContext.Provider>
    )
};
