import React, {Suspense, useState} from "react";

import {ThemeProvider} from "@mui/material";
import {Navigate, Route, Routes} from "react-router-dom";
import {theme} from "./config/theme";
import {AppContextProvider} from "./config/AppContext";
import LoadingScreen from "./pages/LoadingScreen";
import {LoginPage} from "./pages/login-page";
import Homepage from "./pages/Homepage";
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "./config/firebaseApp";
import Dashboard from "./pages/Dashboard";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";

function App() {

    const [user, setUser] = useState(false)

    onAuthStateChanged(auth, async (user) => {
        if (user) {
            console.log('user exists')
            setUser(true)
        }
    })

    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <AppContextProvider>
                    <Suspense fallback={<LoadingScreen/>}>
                        <Routes>
                            {!user ?
                                <>
                                    <Route path="/login" index element={<LoginPage user={user}/>}/>
                                    <Route path="/" index element={<Homepage/>}/>
                                    <Route path="*" element={<Navigate to="/login"/>}/>
                                    <Route path="/privacy-policy" index element={<PrivacyPolicy/>}/>
                                    <Route path="/support" index element={<TermsAndConditions/>}/>
                                </>
                                :
                                <>
                                    <Route path="/" index element={<Homepage/>}/>
                                    <Route path="/dashboard" index element={<Dashboard user={user}/>}/>
                                    <Route path="/login" index element={<LoginPage user={user}/>}/>
                                </>
                            }
                        </Routes>
                    </Suspense>
                </AppContextProvider>
            </ThemeProvider>
        </div>
    );
}

export default App;
