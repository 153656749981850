import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {ThemeProvider} from '@mui/material/styles';
import {useTheme} from "@mui/material";
// import GoogleButton from "react-google-button";
import {
    getAuth,
    GoogleAuthProvider,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup
} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {AppContext} from "../config/AppContext";
import Stack from "@mui/material/Stack";

export function LoginPage({user}) {
    const navigate = useNavigate()

    const provider = new GoogleAuthProvider();
    const theme = useTheme();
    const auth = getAuth()
    const [checked, setChecked] = React.useState(false);
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [pwdResetModal, setPwdResetModal] = useState(false);
    const {state: {currentUser}, dispatch} = useContext(AppContext);
    const [showError, setShowError] = useState(false)


    useEffect(() => {
        console.log('user login.......................')
        console.log(user)

        async function userData() {
            console.log('hydra')
            // await hydrateUserData(dispatch, navigate)
            //TODO: hydrate user or do it in the app.js when user is signed in
        }

        if (user) {
            userData()
        }
    }, [user])

    //  async function hydrateUserData() {
    //     console.log('hyrdatuing')
    //     const docRef = doc(db, "users", auth.currentUser.uid);
    //     const docSnap = await getDoc(docRef);
    //     if (docSnap.exists()) {
    //         dispatch({currentUser: docSnap.data()})
    //         console.log('user set up')
    //         routeUser(docSnap.data().role)
    //     } else {
    //         // try {
    //         //     await setDoc(doc(db, "users", auth.currentUser.uid), {
    //         //         uid: auth.currentUser.uid,
    //         //         joined: Date.now(),
    //         //         images: []
    //         //     });
    //         //     dispatch({
    //         //         currentUser: {
    //         //             uid: auth.currentUser.uid,
    //         //             joined: Date.now(),
    //         //             images: []
    //         //         }
    //         //     })
    //         //     navigate('/dashboard')
    //         // } catch (e) {
    //         //     console.log(e)
    //         // }
    //     }
    // }

    const handleCheckbox = (event) => {
        setChecked(event.target.checked);
    };

    function handleLoginGoogle() {
        console.log('login google')
        signInWithPopup(auth, provider)
            .then(async (result) => {
                // await hydrateUserData(dispatch, navigate)
            }).catch((error) => {
            console.log(error)
            // // Handle Errors here.
            // const errorCode = error.code;
            // const errorMessage = error.message;
            // // The email of the user's account used.
            // const email = error.customData.email;
            // // The AuthCredential type that was used.
            // const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });
    }


    function handleLoginEmailPassword() {
        signInWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
                const user = userCredential.user;
                if (user) {
                    navigate('/dashboard')
                    //TODO: hydrate user or do it in the app.js when user is signed in
                    // await hydrateUserData(dispatch, navigate)
                } else {
                }
            })
            .catch(async (error) => {
                // TODO: allow if you want to have user sign up themselves
                // await createUserWithEmailAndPassword(auth, email, password)
                // await handleLoginEmailPassword()
                const errorCode = error.code;
                const errorMessage = error.message;
                toast.error(errorMessage)
            });
    }


    return (
        <div style={{
            height: '100vh',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundImage: 'url("https://images.unsplash.com/photo-1520087134910-1ca70db10083?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <ThemeProvider theme={theme}>
                {/*<Box sx={{height: 20}}/>*/}
                <Container
                    component="main"
                    maxWidth="xs"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: 5,
                        borderRadius: 2,
                        backgroundColor: 'white',
                        // marginTop: 2
                        margin: 1
                    }}>
                    <Typography variant={"h1"}>
                        <strong>911Lawyer</strong>
                    </Typography>
                    <Typography>Your lawyer in need</Typography>

                    <Grid container spacing={2} alignItems={"center"} alignContent={"center"}
                          justifyContent={"center"}
                          sx={{ mt: 3}}>
                        <Grid item>

                            {/*<GoogleButton*/}
                            {/*    onClick={() => handleLoginGoogle()}*/}
                            {/*/>*/}
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <TextField
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                type={checked ? "text" : "password"}
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                id="password"
                                autoComplete="current-password"
                            />
                        </Grid>

                    </Grid>
                    <Stack sx={{ justifyContent: 'flex-start', width: '100%' }}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={checked}
                                onChange={handleCheckbox}
                                inputProps={{'aria-label': 'controlled'}}
                            />}
                            label="Show Password"
                        />


                    </Stack>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                        style={{color: "white", backgroundColor: theme.palette.primary.blue, borderRadius: "22px"}}
                        onClick={() => handleLoginEmailPassword()}
                    >
                        Sign In
                    </Button>
                    <Typography onClick={() => setPwdResetModal(true)} variant="body1" sx={{cursor: 'pointer'}}>
                        Forgot password?
                    </Typography>
                    {/*<Typography sx={{*/}
                    {/*  "&:hover":*/}
                    {/*    {textDecoration: "underline", textDecorationColor: theme.palette.primary.main, cursor: 'pointer'}*/}
                    {/*}}*/}
                    {/*            onClick={() => navigate('/signup')}*/}
                    {/*>*/}
                    {/*  Don't have an account? Sign up!*/}
                    {/*</Typography>*/}
                    {/*<Typography variant={"body1"} sx={{fontSize: 8, color: 'dimgray'}}>If you don't have an account yet,*/}
                    {/*    signing*/}
                    {/*    in will automatically create your account</Typography>*/}
                </Container>
            </ThemeProvider>
        </div>
    );
}
