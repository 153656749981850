import React from 'react';
import Box from "@mui/material/Box";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import phoneMock from '../assets/phoneMock.png'
import {Header1} from "../components/Header1.js";
import {useNavigate} from "react-router-dom";
import {auth} from "../config/firebaseApp";

const Homepage = () => {
    const navigate = useNavigate()
    return (
        <Box>
            <Header1 isAuth={auth.currentUser}/>
            <Grid container sx={{height: '100vh'}}>
                <Grid item xs={12} md={8}
                      sx={{backgroundColor: '#0a2e5e', color: 'white', p: 5, pt: '30vh'}}
                >
                    <Typography variant={'h1'}><strong>911Lawyer</strong></Typography>
                    {/*<Typography variant={'h1'}><strong>za događaje</strong></Typography>*/}

                    <Button onClick={() => {
                        navigate('/login')
                    }}
                            variant={'contained'}
                            sx={{
                                backgroundColor: 'white', color: '#e4b949', pl: 4, pr: 4, mt: 5,
                                fontSize: '1.5rem', fontWeight: 'bold', alignContent: 'center',
                                '&:hover': {
                                    backgroundColor: 'white',
                                    boxShadow: '0px 5px 10px rgba(255,218,65, 0.5)'
                                },
                                justifyContent: 'center', alignItems: 'center', display: 'flex'
                            }}
                    >

                        Login
                    </Button>
                </Grid>

                <Grid item xs={12} md={4} sx={{backgroundColor: '#ffda41', pt: '30vh'}}>
                    <img src={phoneMock} style={{height: '300px', width: '300px'}} alt={'phone mock'}/>
                </Grid>

            </Grid>

        </Box>
    );
};

export default Homepage;
