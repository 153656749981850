import React, {useContext, useEffect, useState} from 'react';
import {auth, db, functions} from "../config/firebaseApp.js";
import {Header1} from "../components/Header1";
import {doc, getDoc} from "firebase/firestore";
import {AppContext} from "../config/AppContext";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import {httpsCallable} from "firebase/functions";
import Button from "@mui/material/Button";
import {
    Card,
    CardContent,
    CircularProgress,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Tooltip
} from "@mui/material";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import Stack from "@mui/material/Stack";
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';

const getAllCalls = httpsCallable(functions, 'getAllCalls');

const Dashboard = () => {
    const {state: {currentUser}, dispatch} = useContext(AppContext);
    const navigate = useNavigate()
    const [calls, setCalls] = useState([])
    const [nextPageToken, setNextPageToken] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    useEffect(() => {
        if (!auth.currentUser) navigate('/login')
        hydrateUserData()
    }, [auth.currentUser])

    useEffect(() => {
        // Initial load of calls
        loadMoreCalls({nextPageToken: nextPageToken});
    }, []);

    const loadMoreCalls = async () => {
        if (isLoading) return; // Prevent multiple concurrent fetches
        setIsLoading(true);
        setError(null);

        try {
            const response = await getAllCalls({nextPageToken: nextPageToken});
            console.log('response')
            console.log(response)
            // console.log(response.data.calls)
            setCalls(prevCalls => [...prevCalls, ...response?.data.calls]);
            console.log('response.data.nextPageToken');
            console.log(response.data.nextPageToken);
            setNextPageToken(response.data.nextPageToken);
        } catch (err) {
            setError('Error fetching calls');
            console.error('Error fetching calls:', err);
        } finally {
            setIsLoading(false);
        }
    };

    async function hydrateUserData() {
        try {
            const docSnap = await getDoc(doc(db, "users", auth?.currentUser?.uid));
            if (docSnap.exists()) {
                dispatch({currentUser: docSnap.data()})
            }
        } catch (e) {
            console.log(e)
        }
    }

    function openVideo(url) {
        window.open(url, '_blank')
    }

    return (
        <>
            <Header1 isAuth={auth.currentUser}/>
            <Box sx={{margin: 2, p: 2}}>
                {/*<Typography variant={'h1'}><strong>Dashboard</strong></Typography>*/}
                <Typography variant={'h1'}><strong>Call listing</strong></Typography>
                {calls.length === 0 && isLoading ? (
                    <Grid container justifyContent={'center'} direction={"column"} alignContent={"center"}
                          alignItems={"center"} sx={{minHeight: '100vh'}}>
                        <CircularProgress/>
                    </Grid>
                ) : (
                    <List sx={{mt: 2}}>
                        {calls.map((call, index) => (
                            <Card key={index} sx={{marginBottom: 2}}>
                                <CardContent>
                                    <ListItem key={index} element={Card}
                                              sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                        <Stack direction={'column'}>
                                            <Stack direction={'row'}>
                                                <CallReceivedIcon/>
                                                <Typography>{call.members[1]?.email}</Typography>
                                            </Stack>
                                            <Stack direction={'row'}>
                                                <CallMadeIcon/>
                                                <Typography>{call.members[0]?.email}</Typography>
                                            </Stack>
                                        </Stack>

                                        <Stack>
                                            <List sx={{p: 2}}>
                                                {call.recordings.map((recording, recordingIndex) => (
                                                    <Stack key={recordingIndex}>
                                                        <Typography><strong>{`Recording #${recordingIndex + 1}`}</strong></Typography>
                                                        <Stack direction={'row'}>
                                                            <Tooltip title={'Play'}>
                                                                <IconButton onClick={() => openVideo(recording.url)}>
                                                                    <OndemandVideoIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <ListItemText
                                                                primary={`${new Date(recording.start_time).toLocaleString()} : ${new Date(recording.end_time).toLocaleString()}`}
                                                                // secondary={`duration: ${new Date(new Date(recording.end_time)-new Date(recording.start_time)).toISOString().substr(11, 8)} `}
                                                                secondary={`Duration: ${new Date(new Date(recording.end_time) - new Date(recording.start_time)).toISOString().substr(11, 8)}`}

                                                            />
                                                        </Stack>
                                                    </Stack>
                                                ))}
                                            </List>
                                            <ListItemText primary={`Call ID: ${call.callId}`}/>
                                        </Stack>
                                    </ListItem>
                                </CardContent>
                            </Card>
                        ))}
                    </List>
                )}
                {error && <div>{error}</div>}
                {nextPageToken && !isLoading && (
                    <Button variant="contained" onClick={loadMoreCalls}>
                        Load More
                    </Button>
                )}
                {/*//todo: list out all calls from the gs bucket and read from their database*/}
            </Box>
        </>
    );
};

export default Dashboard;
