import createTheme from "@mui/material/styles/createTheme";
import {deepPurple, grey} from "@mui/material/colors";
import {responsiveFontSizes} from "@mui/material";


const fontFamilyHeading = 'Poppins'
const fontFamilyHBody = 'Poppins'

export const theme =
    responsiveFontSizes(createTheme({
        root: {
            position: 'absolute',
            height: '100%'
        },
        palette: {
            primary: {
                // light: will be calculated from palette.primary.main,
                main: '#3962f8',
                contrastText: '#ffffff',
                white: '#ffffff',
                offWhite: '#f4f4f4'
                // dark: will be calculated from palette.primary.main,
                // contrastText: will be calculated to contrast with palette.primary.main
            },
            secondary: {
                main: grey[500]
            },
            neutral: {
                main: '#ffffff',
                gray: '#f4f6f6',
                gray2: '#adadad',
            },
            azure: {
                main: '#29B8D6',
                light: '#E6F8FC',
            },
            confirmation: {
                main: '#43B764',
                contrastText: '#ffffff',
                dark: "#2FA642",
                light: '#E5F5E9'
            },
            purple: {
                main: deepPurple[500],
                light: deepPurple[200],
            }
        },
        typography: {
            fontFamily: fontFamilyHeading,
            h1: {
                fontFamily: fontFamilyHeading,
                fontSize: '2.8rem'
            },
            h2: {
                fontFamily: fontFamilyHeading,
                fontSize: '2.2rem'
            },
            h3: {
                fontFamily: fontFamilyHeading,
                fontSize: '1.5rem'
            },
            h4: {
                fontFamily: fontFamilyHeading,
                fontSize: '1.2rem'
            },
            h5: {
                fontFamily: fontFamilyHeading,
                fontSize: '1rem'
            },
            body1: {
                fontFamily: fontFamilyHBody,
                fontSize: '0.9rem'
            },
            button: {
                textTransform: "none"
            }

        },
        action: {
            hover: '#1B8EB1',
            active: '#1B8EB1',
        }
    }))
