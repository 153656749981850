import React from 'react';
import {terms} from './termsconditions.js';

const TermsAndConditions = () => {
    return (
        <div dangerouslySetInnerHTML={{ __html: terms }}>

        </div>
    );
};

export default TermsAndConditions;
