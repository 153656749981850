import React from 'react';
import {CircularProgress, Grid} from "@mui/material";
// import loadingGif from '../../assets/loading/loading.webp'

const LoadingScreen = () => {
  return (
    <div>
      <Grid container justify={'center'} direction={"column"} alignContent={"center"}
            alignItems={"center"} sx={{minHeight: '100vh'}}>
        <Grid item>
          {/*<img src={loadingGif} alt={'loading gif'}/>*/}
          <CircularProgress/>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoadingScreen;
